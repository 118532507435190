import { NavLink } from 'react-router-dom';
// импорт стилей из родительского компонента
import s from '../sidebar-nav.module.css';
import cn from "classnames";

export const SideBarNavCreator: React.FC<{}> = () => { 
  return (
    <>
      <NavLink
        className={({ isActive}) => cn(s.sidebar__link, isActive ? s.sidebar__link_active : '')}
        to="my-category"
      >
        <p className={s.sidebar__text}>Мои категории</p>
      </NavLink>
      <NavLink
        className={({ isActive}) => cn(s.sidebar__link, isActive ? s.sidebar__link_active : '')}
        to="top"
      >
        <p className={s.sidebar__text}>Топ заявок по сборам</p>
      </NavLink>
      <NavLink
        className={({ isActive}) => cn(s.sidebar__link, isActive ? s.sidebar__link_active : '')}
        to="creator"
      >
        <p className={s.sidebar__text}>Заявки "Я создатель"</p>
      </NavLink>
      <NavLink
        className={({ isActive}) => cn(s.sidebar__link, isActive ? s.sidebar__link_active : '')}
        to="wallet"
      >
        <p className={s.sidebar__text}>Кошелек</p>
      </NavLink>
      <NavLink
        className={({ isActive}) => cn(s.sidebar__link, isActive ? s.sidebar__link_active : '')}
        to="premium-app"
      >
        <p className={s.sidebar__text}>Премиальные заявки</p>
      </NavLink>
      <NavLink
        className={({ isActive}) => cn(s.sidebar__link, isActive ? s.sidebar__link_active : '')}
        to="release"
      >
        <p className={s.sidebar__text}>Релизы</p>
      </NavLink>
    </>
  )
}
import s from './informing.module.css';
import { Button } from '../ui-kit/button/button';


export const Informing: React.FC<{
    children: string;
    title?: string;
    textButton: string;
    width: string;
}> = ({
    children,
    title,
    textButton,
    width
}) => {

    return (
        <article className={s.informing} style={{maxWidth: `${width}`}}>
            {
            title &&
            <h3 className={s.informing__title}>{title}</h3>
            }
            <p className={s.informing__text}>{children}</p>
            <Button type='outline' >{textButton}</Button>
        </article>
    )
}
import React from 'react';
import { getIconColor, TIconProps } from './utils';

export const TwitterIcon = ({ theme }: TIconProps) => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      width="48" 
      height="48" 
      viewBox="0 0 40 33" 
      fill="none" 
    >
      <path 
        d="M39.2687 1.06168C37.6386 1.83168 34.8647 3.27568 33.4706 3.55168C33.4166 3.56568 33.3726 3.58368 33.3206 3.59768C31.6946 1.99368 29.4666 0.999679 27.0006 0.999679C22.0306 0.999679 18.0006 5.02968 18.0006 9.99968C18.0006 10.2617 17.9786 10.7437 18.0006 10.9997C11.5646 10.9997 6.86465 7.64168 3.34665 3.32568C2.87665 2.74568 2.37665 3.04768 2.27265 3.45968C2.03865 4.39168 1.95865 5.94968 1.95865 7.06168C1.95865 9.86368 4.14865 12.6157 7.55865 14.3217C6.93065 14.4837 6.23865 14.5997 5.51865 14.5997C4.67065 14.5997 3.69465 14.3777 2.84065 13.9297C2.52465 13.7637 1.84265 13.8097 2.04465 14.6177C2.85465 17.8557 6.55065 20.1297 9.85265 20.7917C9.10265 21.2337 7.50265 21.1437 6.76665 21.1437C6.49465 21.1437 5.54865 21.0797 4.93665 21.0037C4.37865 20.9357 3.52065 21.0797 4.23865 22.1677C5.78065 24.5017 9.26865 25.9677 12.2706 26.0237C9.50665 28.1917 4.98665 28.9837 0.656649 28.9837C-0.219351 28.9637 -0.175351 29.9617 0.530649 30.3317C3.72465 32.0077 8.95665 32.9997 12.6946 32.9997C27.5546 32.9997 36.0006 21.6737 36.0006 10.9977C36.0006 10.8257 35.9967 10.4657 35.9907 10.1037C35.9907 10.0677 36.0006 10.0337 36.0006 9.99768C36.0006 9.94368 35.9846 9.89168 35.9846 9.83768C35.9786 9.56568 35.9726 9.31168 35.9666 9.17968C37.1446 8.32968 38.9487 6.85368 39.8606 5.72368C40.1707 5.33968 39.9207 4.87368 39.4986 5.01968C38.4127 5.39768 36.5346 6.12968 35.3587 6.26968C37.7127 4.71168 38.8766 3.35568 39.8766 1.84968C40.2186 1.33568 39.7906 0.813679 39.2687 1.06168Z" 
        fill={getIconColor(theme)}
      />
    </svg>
  );
};
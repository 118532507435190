export const data_req = [
  {
    '_id': 1,
    'req_title':'Провести журналистское расследование о введении QR кодов',
    'req_status': false,
    'req_rating':143,
    'req_category':'',
    'req_location':''
  },
  {
    '_id': 2,
    'req_title':'Подготовить репортаж о химкинском лесе',
    'req_status': true,
    'req_rating':684,
    'req_category':'',
    'req_location':''
  },
  {
    '_id': 3,
    'req_title':'Подготовить аналитическую статью о треованиях к персоналу авиакомпаний...',
    'req_status':true,
    'req_rating':113,
    'req_category':'',
    'req_location':''
  },
  {
    '_id': 4,
    'req_title':'Провести журналистское расследование о коллективном имунитете России',
    'req_status':false,
    'req_rating':776,
    'req_category':'',
    'req_location':''
  },
  {
    '_id': 5,
    'req_title':'Провести журналистское расследование о коррупционном обвинении в взяточничестве первого замначальника главка МЧС ',
    'req_status':false,
    'req_rating':287,
    'req_category':'',
    'req_location':''
  },
  {
    '_id': 6,
    'req_title':'Провести журналистское расследование о введении QR кодов',
    'req_status':false,
    'req_rating':374,
    'req_category':'',
    'req_location':''
  },
  {
    '_id': 7,
    'req_title':'Подготовить репортаж о химкинском лесе',
    'req_status':true,
    'req_rating':123,
    'req_category':'',
    'req_location':''
  },
  {
    '_id': 8,
    'req_title':'Подготовить аналитическую статью о треованиях к персоналу авиакомпаний...',
    'req_status':true,
    'req_rating':639,
    'req_category':'',
    'req_location':''
  },
  {
    '_id': 9,
    'req_title':'Провести журналистское расследование о коллективном имунитете России',
    'req_status':false,
    'req_rating':7,
    'req_category':'',
    'req_location':''
  }
] 
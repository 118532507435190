type TIconTheme = 'dark' | 'light';

export type TIconProps = { theme: TIconTheme; };

export const getIconColor = (theme: TIconTheme) => {
  return `${
    theme === `dark`
      ? '#130224'
      : '#ffff'
  }`
}
import { makeAutoObservable } from "mobx"
import { data_actual } from '../utils/data_actual';
import { data_req } from '../utils/data_req';
import { IReqData, IActualData } from '../types';



class data {
  actual:IActualData[] = data_actual
  req:IReqData[] = data_req

  constructor(){
    makeAutoObservable(this)
  }

  incRating(id:number) {
    this.req = this.req.map(e => e._id === id ? {...e, req_rating: e.req_rating+1}: e)
  }

  decRating(id: number) {
    this.req = this.req.map(e => e._id === id ? {...e, req_rating: e.req_rating-1}: e)
  }

}

export default new data()
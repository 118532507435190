import s from './main-how-it-works.module.css';
import { HowItWorksCard } from '../how-it-works-card/how-it-works-card';
import { howItWorksFirst, howItWorksSecond, howItWorksThird, howItWorksFourth } from '../../images';

export const MainHowItWorks: React.FC<{}> = () => {
  return(
    <div className={s.main__howItWorks}>
      <HowItWorksCard 
        title='Расскажите о Вашей проблеме'
        number={1}
        icon={howItWorksFirst}
      >
        Это может беспокоить Вас, Ваш город или социальную группу
      </HowItWorksCard>
      <HowItWorksCard 
        title='Назначьте награду за освещение Вашей проблемы'
        number={2}
        icon={howItWorksSecond}
      >
        Чем большее число людей беспокоит тоже, чтои вас, тем больше награда ожидает исполнителя. 
        Если ваша проблема не вызвала достаточного интереса, вы получите деньги обратно
      </HowItWorksCard>
      <HowItWorksCard 
        title='Оцените качество выполненной работы'
        number={3}
        icon={howItWorksThird}
      >
        Наша цель - мотивировать авторов создавать качественный, объективный и честный контент. 
        Если за результат их работы проголосует недостаточно людей, они не получат основную награду
      </HowItWorksCard>
      <HowItWorksCard 
        title='Ищите и объединяйтесь с единомышленниками'
        number={4}
        icon={howItWorksFourth}
      >
        Общайтесь и заводите друзей. Пока мы разъеденены - мы слабы
      </HowItWorksCard>
    </div>
  )
}
import s from './main-page.module.css'
import cn from 'classnames';
import { MainControl } from '../../components/main-control/main-control';
import { MainContentCards } from '../../components/main-content-cards/main-content-cards';
import { MainHowItWorks } from '../../components/main-how-it-works/main-how-it-works';
import { Informing } from '../../components/informing/informing';



export const MainPage: React.FC<{}> = () => {
  return(
    <div className={cn('content', s.main__content)}>
      <MainControl />
      <MainContentCards />
      <MainHowItWorks />
      <Informing
        width='771px'
        textButton='Узнать'
      >
        Узнайте больше о соуправлении медиа и нашей платформе
      </Informing>
      <Informing 
        width='830px'
        textButton='Попробовать'
        title='Получите стартовую сумму и примите собственное решение'
      >
        Вам не придется привязывать карту и пополнять счет, что бы попробовать платформу
      </Informing>
    </div>
  )
}
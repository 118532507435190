import React, { SyntheticEvent } from 'react';
import s from './button.module.css';

export const Button: React.FC<{
  onClick?: (() => void) | ((e: SyntheticEvent) => void);
  htmlType?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  name?: string;
  type?: 'background' | 'outline';
  children? : string;
  className? : string | void;
}> = ({
  children,
  onClick,
  disabled = false,
  htmlType,
  name,
  type = 'backgound',
  className
}) => {
  const classNames = `${s.button} ${className} ${
    type === 'background'
      ? s.button_type_background 
      : s.button_type_outline
  }`
  return (
    <button
      type={htmlType}
      disabled={disabled}
      name={name}
      onClick={onClick}
      className={classNames}
    >
      {children}
    </button>
  );
};
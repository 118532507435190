import React, { useEffect, FC } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import s from './modal.module.css';
import { ModalOverlay } from '../modal-overlay/modal-overlay';


const modalRoot: Element | null = document.querySelector('#modal');

export const Modal: FC = ({ children }) => {
  let navigate = useNavigate();

  const closeModal = () => {
    navigate(-1);
  }

  const close = (event: KeyboardEvent) => {
    if (event.code === 'Escape') closeModal()
  }

  useEffect(() => {
    window.addEventListener('keydown', close)
    return () => {
      window.removeEventListener('keydown', close)
    }
  })


  return modalRoot && ReactDOM.createPortal ((
    <>
      <div className={cn(s.popup)}>
        <div className={cn(s.popup__header)}>
          <button className={cn(s.popup__button_type_close)} onClick={closeModal}/>
        </div>
        {children}
      </div>
      <ModalOverlay closeModal={closeModal} />
    </>
  ), modalRoot) 
}
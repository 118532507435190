// импорт стилей из родительского компонента
import s from '../sidebar-nav.module.css';
import { NavLink } from 'react-router-dom';
import cn from "classnames";

export const SideBarNavConsumer: React.FC<{}> = () => { 
  return (
    <>
      <NavLink
        className={({ isActive}) => cn(s.sidebar__link, isActive ? s.sidebar__link_active : '')}
        to="/actual"
      >
        <p className={s.sidebar__text}>Актуальные инфоповоды</p>
      </NavLink>
      <NavLink
        end
        className={({ isActive}) => cn(s.sidebar__link, isActive ? s.sidebar__link_active : '')}
        to="/request"
      >
        <p className={s.sidebar__text}>Заявки</p>
      </NavLink>
      
      <NavLink
        end
        className={({ isActive}) => cn(s.sidebar__link, isActive ? s.sidebar__link_active : '')}
        to="/create-app"
      >
        <p className={s.sidebar__text}>Новая заявка</p>
      </NavLink>
      <NavLink
        className={({ isActive}) => cn(s.sidebar__link, isActive ? s.sidebar__link_active : '')}
        to="/apps/supported"
      >
        <p className={s.sidebar__text}>Поддержанные релизы</p>
      </NavLink>
      <NavLink
        className={({ isActive}) => cn(s.sidebar__link, isActive ? s.sidebar__link_active : '')}
        to="/apps/wallet"
      >
        <p className={s.sidebar__text}>Кошелек</p>
      </NavLink>
      <NavLink
        className={({ isActive}) => cn(s.sidebar__link, isActive ? s.sidebar__link_active : '')}
        to="/create-app/faq"
      >
        <p className={s.sidebar__text}>FAQ</p>
      </NavLink>
      <NavLink
        className={({ isActive}) => cn(s.sidebar__link, isActive ? s.sidebar__link_active : '')}
        to="/my-app"
      >
        <p className={s.sidebar__text}>Мои заявки</p>
      </NavLink>
    </>
  )
}
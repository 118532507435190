import { Link } from 'react-router-dom';
import s from './main-content-cards.module.css';
import cn from 'classnames';
import { MainCard } from '../main-card/main-card';
import { all, applications, marker, newspaper, screw, card } from '../../images';

export const MainContentCards: React.FC<{}> = () => {
  return (
    <div className={s.main__contentCards}>
      <h2 className={s.contentCards__title}>Потребление и создание контента</h2>
      <Link to='/' className={cn('link', s.contentCards__link)}>Заявки где я исполнитель &rarr;</Link>
      <div className={s.contentCards__cards}>
        <MainCard path='/' icon={screw}>Топ заявок по сборам</MainCard>
        <MainCard path='/' icon={applications}>Поддержанные релизы и мои заявки</MainCard>
        <MainCard path='/' icon={card}>Актуальные инфоповоды, главное</MainCard>
        <MainCard path='/' icon={newspaper}>Все опубликованные релизы</MainCard>
        <MainCard path='/' icon={marker}>Главное в моих категориях</MainCard>
        <MainCard path='/' icon={all}>Все заявки на репортажи</MainCard>
      </div>
    </div>
  )
}
import React, { FC } from 'react';
import { Routes, Route, useLocation, useNavigationType } from 'react-router-dom';
import cn from 'classnames';
import s from './app.module.css';
import { Header } from '../header/header';
import { Footer } from '../footer/footer';
import { MainPage } from '../../pages/MainPage/main-page';
import { Request } from '../request/request';
import { Auth } from '../auth/auth';
import { Modal } from '../modal/modal';
import { Layout } from '../../pages/layout/layout';
import { Actual } from '../actual/actual';


const App: FC = () => {
  let location = useLocation();
  const navigationType = useNavigationType();
  const background = (navigationType === 'PUSH' || navigationType === 'REPLACE') && location.state && location.state.background
  return (
    <div className={cn(s.page)}>
      <Header />
      <Routes location={background || location}>
        <Route path="/" element={ <MainPage /> } /> {/* Главная */}
        <Route path="/auth" element={<Auth />} /> {/* Авторизация */}
        {/* Потребитель - consumer */}
        <Route path='/request' element={<Layout > <Request /> </Layout>} > {/* Заявки */}
          <Route path='wallet' element={<Layout />} /> {/* Кошелек */}
          <Route path='supported' element={<Layout />} /> {/* Поддержанные релизы */}
        </Route>
        <Route path='/actual' element={<Layout> <Actual /> </Layout>} /> {/* Актуальные инфоповоды */}
        <Route path='/my-app' element={<Layout />} /> {/* Мои заявки */}
        <Route path='/create-app' element={<Layout />} > {/* Создать заявку */}
          <Route path='faq' element={<Layout />} /> {/* FAQ */}
        </Route>
        {/* Создатель - creator */}
        <Route path='suggest-content/my-categories' element={<Layout />} />


      </Routes>
      <Footer />
      {background &&
      (
      <>
        <Routes>
          <Route path="/auth" element={<Modal><Auth /></Modal>} />
        </Routes>
      </>
      )}
    </div>
  );
}

export default App;

import React from 'react';
import s from './social-icon.module.css';
import { TwitterIcon, FacebookIcon, InstagramIcon, YoutubeIcon } from '../icons'

export const SocialIcon: React.FC <{
  type?: 'twitter' | 'facebook' | 'instagram' | 'youtube';
  theme?:'dark' | 'light';
}> = ({
  type = 'twitter',
  theme = 'dark'
}) => {
  const link = `${
    type === 'twitter' 
    ? 'https://twitter.com'
    : type === 'facebook'
      ? 'https://facebook.com'
      : type === 'instagram'
        ? 'https://instagram.com'
        : 'https://youtube.com'
  }`

  const icon = 
    type === 'twitter'
    ? <TwitterIcon theme={theme} />
    : type === 'facebook'
      ? <FacebookIcon theme={theme} />
      : type === 'instagram'
        ? <InstagramIcon theme={theme} />
        : <YoutubeIcon theme={theme} />

  return (
    <a 
    href={link} 
    target='_blank' 
    rel="noreferrer"
    className={`${s.link}`}
    >
      {icon}
    </a>
  );
};


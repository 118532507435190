import s from './how-it-works-card.module.css';

export const HowItWorksCard: React.FC<{
  title: string;
  children: string;
  number: number;
  icon: string;
}> = ({
  title,
  children,
  number,
  icon
}) => {
  const className = `${s.card__info} ${
    number === 1
      ? s.card__info_type_one
      : number === 2
        ? s.card__info_type_two
        : number === 3
          ? s.card__info_type_three
          : s.card__info_type_four
  }`

  return(
    <article className={s.howItWorks__card}>
      <div className={className}>
        <h3>{title}</h3>
        <p>{children}</p>
      </div>
      <div className={s.card__img} style={{ backgroundImage: `url(${icon})` }}></div>
    </article>
  )
}
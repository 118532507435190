import { makeAutoObservable } from "mobx"

class switchRole {
  role = true // true = 'creator', false = 'consumer'
  constructor() {
    makeAutoObservable(this)
  }

  choiceCreator() {
    this.role = true
  }

  choiceConsumer() {
    this.role = false
  }
}

export default new switchRole()
import React, { useState } from "react";
import { NavLink, useLocation, Link } from "react-router-dom";
import s from './header.module.css';
import cn from "classnames";
import logoPlaceHeader from '../../images/logo-place-header.png';
import switchRole from "../../store/switchRole";
import { observer } from "mobx-react-lite";

export const Header: React.FC<{}> = observer(() => {
  let location = useLocation()
  const [auth, setAuth] = useState(false);
  const handleToggleAuth = (event: any) => {
    setAuth(auth => !auth)
  };

  return(
    <header className={s.header}>
      <div className={cn('content', s.header__content)}>
        <Link className={s.header__logo} to='/'>
          <img  src={logoPlaceHeader} alt="Логотип Verdict" />
        </Link>
        <nav className={s.header__nav}>
          <NavLink 
            className={({ isActive}) => cn('link', isActive ? s.header__link_active : s.header__link)}
            to="/request"
            onClick={() => {switchRole.choiceConsumer();}}
          >
            Заявки
          </NavLink>
          <NavLink 
            className={({ isActive}) => cn('link', isActive ? s.header__link_active : s.header__link)} 
            to="/my-app"
            onClick={() => {switchRole.choiceConsumer();}}
          >
            Мои заявки
          </NavLink>
          <NavLink 
            className={({ isActive}) => cn('link', isActive ? s.header__link_active : s.header__link)} 
            to="/create-app"
            onClick={() => {switchRole.choiceConsumer();}}
          >
            Создать заявку
          </NavLink>
          <NavLink 
            className={({ isActive}) => cn('link', isActive ? s.header__link_active : s.header__link)} 
            to="/suggest-content/my-categories"
            onClick={() => {switchRole.choiceCreator();}}
          >
            Предложить контент
          </NavLink> 
        </nav>
        
          { auth ? (
            <div className={cn(s.header__auth, s.header__auth_type_authorized)}>
              <a
                className={cn('link', s.header__link_type_authorized)}
                onClick={handleToggleAuth}
              />
            </div>
            ) : (
            <div className={s.header__auth}>
              <Link
                className={cn('link', s.header__link)}
                to="/auth"
                state={{background: location}}
              >
                <span className={s.header__link_type_auth}>Вход</span>
              </Link>
                <p> или </p> 
              <a
                className={cn('link', s.header__link)} 
                onClick={handleToggleAuth}
              >
                <span className={s.header__link_type_auth}>Регистрация</span>
              </a>
            </div>
            )
            }
        
      </div>
    </header> 
  )
})
import s from './footer.module.css'
import { logoPlaceFooter }from '../../images';
import { SocialIcon } from '../ui-kit/social-icon/social-icon';
import { Link } from "react-router-dom";
import cn from 'classnames';


export const Footer: React.FC<{}> = () => {
    return(
        <footer className={s.footer}>
            <div className={s.footer__container}>
                <Link to='/' className={cn('link', s.footer__logo_link)}>
                    <img className={s.footer__logo} src={logoPlaceFooter} alt="Логотип Verdict" />
                </Link>
                <nav className={s.footer__links}>
                    <Link to='/'className={cn('link', s.footer__link)}>Пользовательское соглашение</Link>
                    <Link to='/'className={cn('link', s.footer__link)}>Блог</Link>
                    <Link to='/'className={cn('link', s.footer__link)}>Контакты</Link>
                    <Link to='/'className={cn('link', s.footer__link)}>Персональные данные</Link>
                    <Link to='/'className={cn('link', s.footer__link)}>Поддержка</Link>
                </nav>
                <p className={s.footer__copyright}>Copyright 2021</p>
                <nav className={s.footer__social}>
                    <SocialIcon theme='light' type='twitter'/>
                    <SocialIcon theme='light' type='facebook'/>
                    <SocialIcon theme='light' type='instagram'/>
                    <SocialIcon theme='light' type='youtube'/>
                </nav>
            </div>
        </footer>
    )
}
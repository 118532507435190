import s from './request.module.css';
import cn from 'classnames';
import { Button } from '../ui-kit/button/button';
import data from '../../store/data';
import { observer } from 'mobx-react-lite';
import { test } from '../../images';
import { useState } from 'react';

export const Request: React.FC<{
}> = observer(() => {
  const store = data.req;
  const [search, setNewSearch] = useState("");

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(typeof e)
    setNewSearch(e.target.value);
};

const filtered = !search 
    ? store
    : store.filter((post) =>
        post.req_title.toLowerCase().includes(search.toLowerCase())
      );

  return(
    <div className={s.container}>
      <h2 className={s.title}>Заявки</h2>
      <form name='search'>
        <div className={s.search__container}>
          <input 
            className={s.search__input}
            type="text" 
            placeholder='Поиск по ключевым словам' 
            onChange={handleSearchChange} 
            value={search}
          />
          <Button
            className={s.search__button}
            htmlType='submit'
            name='search'
            type='background'
          >
            Найти
          </Button>
        </div>
        <div className={s.filter__container}>
          <label className={s.filter__label}>Категория
            <select
              className={s.filter__select}
              name="category"
              id="category-select"
            >
              <option value=''>Все</option>
              <option value='Community'>Общество</option>
              <option value='Ecology'>Экология</option>
              <option value='IT'>ИТ</option>
              <option value='Science'>Наука</option>
              <option value='Sport'>Спорт</option>
            </select>
          </label>
          <label className={s.filter__label}>Локация
            <input
              type="text"
              placeholder="Земля"
              className={s.filter__input}
            />
          </label>
        </div>
      </form>
      <div className={s.result__container}>
        {filtered.map((post) => {
          return (
            <article className={s.result__item} key={post._id}>
              <div className={s.item__container}>
                <img
                  src={test}
                  alt='logo_item'
                  className={s.item__image}
                />
                <div className={s.item__info}>
                  <p className={s.item__title}>{post.req_title}</p>
                  {
                    post.req_status ? 
                      <p className={cn(s.item__status, s.item__status_done)}>Выполнена</p>
                        :
                      <p className={cn(s.item__status, s.item__status_open)}>Открыта</p>
                  }
                </div>
              </div>
              <div className={s.rating__container}>
                <button
                  className={cn(s.rating__button ,s.rating__button_inc)}
                  onClick={()=> data.incRating(post._id)}
                />
                <p className={s.rating}>{post.req_rating}</p>
                <button
                  className={cn(s.rating__button ,s.rating__button_dec)} 
                  onClick={()=> data.decRating(post._id)}
                />
              </div>
            </article>
          )
        })}
      </div>
    </div>
  )
})
import React from 'react';
import { getIconColor, TIconProps } from './utils';

export const YoutubeIcon = ({ theme }: TIconProps) => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      width="48" 
      height="48" 
      viewBox="0 0 48 48" 
      fill="none" 
    >
      <path 
        d="M43.164 12.372C42.704 10.652 41.348 9.296 39.628 8.836C36.508 8 24 8 24 8C24 8 11.492 8 8.372 8.836C6.652 9.296 5.296 10.652 4.836 12.372C4 15.492 4 24 4 24C4 24 4 32.508 4.836 35.628C5.296 37.348 6.652 38.704 8.372 39.164C11.492 40 24 40 24 40C24 40 36.508 40 39.628 39.164C41.35 38.704 42.704 37.348 43.164 35.628C44 32.508 44 24 44 24C44 24 44 15.492 43.164 12.372ZM20 29.196V18.804C20 18.034 20.834 17.554 21.5 17.938L30.5 23.134C31.166 23.518 31.166 24.482 30.5 24.866L21.5 30.062C20.834 30.448 20 29.966 20 29.196Z" 
        fill={getIconColor(theme)}
      />
    </svg>

  );
};
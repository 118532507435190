import React from 'react';
import { getIconColor, TIconProps } from './utils';

export const InstagramIcon = ({ theme }: TIconProps) => {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none" 
    >
      <path 
        d="M32 6H16C10.478 6 6 10.478 6 16V32C6 37.522 10.478 42 16 42H32C37.522 42 42 37.522 42 32V16C42 10.478 37.522 6 32 6ZM24 34C18.478 34 14 29.522 14 24C14 18.478 18.478 14 24 14C29.522 14 34 18.478 34 24C34 29.522 29.522 34 24 34ZM36 14C34.896 14 34 13.104 34 12C34 10.896 34.896 10 36 10C37.104 10 38 10.896 38 12C38 13.104 37.104 14 36 14Z" 
        fill={getIconColor(theme)}
      />
      <path 
        d="M24 18C22.4087 18 20.8826 18.6321 19.7574 19.7574C18.6321 20.8826 18 22.4087 18 24C18 25.5913 18.6321 27.1174 19.7574 28.2426C20.8826 29.3679 22.4087 30 24 30C25.5913 30 27.1174 29.3679 28.2426 28.2426C29.3679 27.1174 30 25.5913 30 24C30 22.4087 29.3679 20.8826 28.2426 19.7574C27.1174 18.6321 25.5913 18 24 18Z" 
        fill={getIconColor(theme)}
      />
    </svg>
  );
};
export const data_actual = [
  {
    "actual_theme":"Рекорды смертности: как в России развивается эпидемия COVID. Инфографика",
  },
  {
    "actual_theme":"Киев потребовал опровергнуть слова президента Болгарии о российском Крыме",
  },
  {
    "actual_theme":"Канцлер Австрии объявил всеобщую вакцинацию из-за антипрививочников",
  },
  {
    "actual_theme":"Уровень коллективного иммунитета к коронавирусу в России превысил 50%",
  },
  {
    "actual_theme":"Кремль счел нелепым предложение США не признавать Путина после 2024 года",
  },
  {
    "actual_theme":"В Москве заподозрили во взяточничестве первого замначальника главка МЧС",
  },
  {
    "actual_theme":"Цена нефти Brent упала ниже $79 впервые с 4 октября",
  },
  {
    "actual_theme":"Семь из десяти россиян назвали свое материальное положение трудным",
  },
  {
    "actual_theme":"«Яндекс» счел манипуляцией заявления о дорожании топлива из-за комиссий",
  },
  {
    "actual_theme":"Мишустин расширил полномочия Белоусова и двух вице-премьеров",
  },
  {
    "actual_theme":"Российская фигуристка первой в истории исполнила четверной риттбергер",
  },
  {
    "actual_theme":"Что известно о пожаре в доме Марины Хлебниковой. Видео",
  },
  {
    "actual_theme":"Самое длительное с XV века лунное затмение. Фотогалерея ",
  },
  {
    "actual_theme":"Activision Blizzard тонет в скандалах. Что будет с акциями компании ",
  },
  {
    "actual_theme":"Почему топ-менеджеры так хотят вернуться в офисы ",
  }
] 
import s from './switch-role.module.css';
import cn from 'classnames';
import { Link } from "react-router-dom";

import switchRole from '../../store/switchRole';
import { observer } from 'mobx-react-lite';

export const SwitchRole: 
  React.FC<{
    className?: string;
  }> 
  = observer (({ className }) => {
  // true = 'creator' - создатель , false = 'consumer' - потребитель ("управляющий")
  return(
    <div className={cn(s.container, className)}>
      <Link
        className={cn(( switchRole.role ? s.button_type_consumer : s.button_active_type_consumer), 
        s.button, 'link' )}
        onClick={()=>{switchRole.choiceConsumer();}}
        to='/actual'
      >
          Управлять
      </Link>
      <Link
        className={cn(( switchRole.role ? s.button_active_type_creator : s.button_type_creator),
        s.button, 'link' )}
        onClick={()=>{switchRole.choiceCreator();}}
        to='/suggest-content/my-categories'
      >
          Создавать
      </Link>
    </div>
  )
})
import s from './layout.module.css';
import { SwitchRole } from '../../components/switch-role/switch-role';
import { SideBarNav } from '../../components/sidebar-nav/sidebar-nav';
import React from 'react';

export const Layout: React.FC<{
  children?: React.ReactNode
}> = ({
  children
}) => {
  return(
    <div className='pages__container'>
      <SideBarNav />
      <SwitchRole  className={s.switch}/>
      <div className={s.child__container}>
        { children }
      </div>
    </div>
  )
}
import { Button } from "../ui-kit/button/button";
import s from './main-control.module.css';
import { mainNews } from "../../images";

export const MainControl: React.FC<{}> = () => {
  return (
    <div className={s.main__control}>
        <div className={s.control__info}>
          <h1 className={s.control__title}>Верните себе контроль над медиа</h1>
          <ol className={s.control__items}>
            <li className={s.control__item}>Расскажите о проблеме</li>
            <li className={s.control__item}>Профинансируйте освещение в медиа</li>
            <li className={s.control__item}>Проконтролируйте качество</li>
          </ol>
          <div className={s.control__buttons}>
            <Button type='background'>Участвовать</Button>
            <Button type='background'>Взять в работу</Button>
          </div>
        </div>
        <img className={s.control__img} src={mainNews}  alt='надпись "Новости"'/>
    </div>
  )
}

import React from 'react';
import s from './main-card.module.css';
import cn from 'classnames';
import { Link } from 'react-router-dom';


export const MainCard: React.FC<{
  icon: string;
  children? : string;
  path: string
}> = ({
  children,
  icon,
  path
}) => {

  
  return (
    <Link className={cn(s.card, 'link')} to={path}>
      <div className={s.card__circle} style={{ backgroundImage: `url(${icon})` }}></div>
      <p className={s.card__info}>{children}</p>
    </Link>
  )
}

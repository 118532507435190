import s from './auth.module.css';
import { logoPlaceHeader } from '../../images';
import { Button } from '../ui-kit/button/button';
import React, { useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

export const Auth: React.FC<{}> = () => {

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = (event: any) => {
    event?.preventDefault()
    setShowPassword(showPassword => !showPassword)
  };
  return (
    <div className={(s.container)}>
      <img className={s.auth__logo} src={logoPlaceHeader} alt="Логотип Verdict" />
      <h3 className={s.auth__title}>Вход или регистрация</h3>
      <form className={s.auth__form}>
        <label className={s.auth__label}>Электронная почта
          <input
            className={cn(s.auth__input, s.auth__input_type_email)}
            type='email'
            name='email'
            id='email'
            placeholder='@'
          />
        </label>
        <label className={s.auth__label}>Пароль
          <input 
            className={cn(s.auth__input, s.auth__input_type_password)}  
            type= {showPassword ? 'text' : 'password'}
            name='password'
            id='password'
            placeholder='&bull;&bull;&bull;&bull;&bull;'
          />
          <button className={cn(s.auth__button_type_password, showPassword ? s.password__button_type_active : s.password__button_type_disabled)} onClick={handleTogglePassword} />  
        </label>
        <Button className={s.auth__button_type_submit} type='background'>Войти</Button>
      </form>
      <p
        className={s.auth__inform}  
      >
        Впервые на <span className={s.auth__inform_medium}>Verdict? </span>
        <Link to='' className={cn('link', s.auth__inform_medium, s.auth__link_type_register)}>
          Регистрация
        </Link>
      </p>
      <Link to='' className={cn('link', s.auth__inform_medium, s.auth__link_type_remind)}>
        Напомнить пароль
      </Link>
    </div>
  )
}
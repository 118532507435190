import switchRole from '../../store/switchRole';
import { observer } from 'mobx-react-lite';
// true = 'creator', false = 'consumer'
import s from './sidebar-nav.module.css'
import { SideBarNavCreator } from './sidebar-nav-creator/sidebar-nav-creator';
import { SideBarNavConsumer } from './sidebar-nav-consumer/sidebar-nav-comsumer';


export const SideBarNav: React.FC<{}> = observer(() => { 
  return (
    <nav className={s.sidebar}>
      { switchRole.role ? <SideBarNavCreator />  : <SideBarNavConsumer /> }
    </nav>
  )
})
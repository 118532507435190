import s from './actual.module.css';
import data from '../../store/data';
import { observer } from 'mobx-react-lite';

export const Actual: React.FC<{}> = observer(() => {


  return (
    <div className={s.container}>
      <h1 className={s.title}>Актуальные инормационные поводы</h1>
      <ul className={s.list}>
        {data.actual.map((e) => {
          return (
            <li className={s.list__item}>{e.actual_theme}</li>
          )
        })}
      </ul>
    </div>
  )
})
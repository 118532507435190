import React from 'react';
import { getIconColor, TIconProps } from './utils';

export const FacebookIcon = ({ theme }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48" 
      height="48" 
      viewBox="0 0 48 48" 
      fill="none"
    > 
      <path 
        d="M24 4C12.954 4 4 12.954 4 24C4 34.79 12.55 43.56 23.242 43.962V30.078H18.556V24.628H23.242V20.618C23.242 15.97 26.084 13.436 30.232 13.436C31.63 13.432 33.026 13.504 34.416 13.646V18.506H31.56C29.3 18.506 28.86 19.574 28.86 21.15V24.62H34.26L33.558 30.07H28.828V43.388C37.536 41.226 44 33.378 44 24C44 12.954 35.046 4 24 4Z" 
        fill={getIconColor(theme)}
      />
    </svg>
  );
};
import React, { FC } from 'react';
import s from './modal-overlay.module.css';

export const ModalOverlay: FC<{
  closeModal: () => void;
}> = ({
  closeModal
}) => {
  return (
    <div 
      className={s.overlay}
      onClick={closeModal}
    >
    </div>
  )
}
